.login-carousel-caption {
    font-family: "Poppins";
    font-size: 17px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: #818181;
}

.login-carousel-heading {
    font-family: "Poppins";
    font-size: 21px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
    text-align: center;
    color: #181818;
}

.carousel-control-prev {
    display: none !important;
}

.carousel-control-next {
    display: none !important;
}

.carousel-caption {
    padding-top: 0px !important;
    top: 60% !important;
}

.login-container {
    /* overflow-x: hidden; */
}

#basic-addon1 {
    background-color: #ffff !important;
}

#button {
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    color: #FFFFFF;
    background: #1C1C1C;
    border-radius: 7px;
    box-shadow: 0px 0px 14px 0px #00000026;
}

#sidebar {
    width: 96%;
    border-radius: 7px;
    padding-left: 7%;
    padding-top: 2%;
    padding-bottom: 2%;
    list-style: none;
    color: #313D15;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    /* line-height: 60px; */
    text-align: left;
    cursor: pointer;
}

.logo-text {
    font-family: "Poppins";
    font-size: 21px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #000000;
    margin-top: 15px;
}

body {
    /* background-color:#F9FAF5; */
    background-image: url('../image/Bg\ \(2\).svg');
    overflow-x: hidden;
}

li.active {
    background-color: #515151;
}

li:hover {
    background-color: #1C1C1C;
    color: white;
}

a {
    text-decoration: none;
    color: #0a0909;
}

li.active a {
    color: #FFFFFF;
    text-decoration: none;
}

li a:hover {
    color: white;
}

.user-management-table-heading {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    background-color: #515151;
    color: #ffff;
}

.user-management-table-detail {
    font-family: "Poppins";
    font-size: 13px;
    font-weight: normal;
    line-height: 17.38px;
    font-weight: 400;
    /* text-align: left; */
    color: #717171
}

.fixTableHead {
    overflow-y: auto;
    overflow-x: auto;
    /* height: 75vh; */
}

/* 
.table>:not(caption)>*>*{
    padding: 0px 5px 5px 0px !important;
} */

.layout-content {
    max-width: 96%;
    box-shadow: 0px 0px 24px 0px #0000001F;
    /* border: 4px solid black; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #FFFFFF;
}

.table-responsive {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.header-text {
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 500;
    line-height: 55px;
    text-align: left;
    color: #1C1C1C;
}

.form-label {
    font-family: "Poppins";
}

input::placeholder {
    font-family: "Poppins";
}

.modal-dialog {
    /* margin-top: 176px; */
    /* width: 220px; */
}

.modal {
    left: unset !important;
    right: 12%;
}

.order-total {
    margin-left: 77%;
}

.model-close {
    cursor: pointer;
}

.table {
    margin: 0px !important;
}

.comnToggle {
    float: left;
    width: auto;
}

.comnToggle i {
    font-size: 25px;
    cursor: pointer;
    line-height: normal;
}

.comnToggle .bi-toggle2-on {
    color: #008fff;
}

.ToggleMainWrap {
    display: flex;
    float: left;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
}

.ToggleMainWrap {
    background-color: #080606 !important;
}

.detail-icon {
    cursor: pointer;
}

.paginationCustom {
    float: left;
    width: 100%;
    padding: 0px;
    margin: 9px 0px 9px;
}

.paginationCustom li:first-child button,
.paginationCustom li:last-child button,
.paginationCustom li:nth-child(2) button,
.paginationCustom li:nth-last-child(2) button {
    color: black;
    position: relative;
}

.paginationCustom li:first-child button::before {
    /* content: "\F22D"; */
    font-family: 'bootstrap-icons';
    color: #333;
    opacity: 1;
    font-size: 17px;
    position: absolute;
    left: 6px;
    line-height: normal;
}

.paginationCustom li:nth-child(2) button::before {
    /* content: "\F129"; */
    font-family: 'bootstrap-icons';
    color: #333;
    opacity: 1;
    font-size: 20px;
    position: absolute;
    left: 5px;
    line-height: normal;
}

.paginationCustom li:last-child button::before {
    /* content: "\F133"; */
    font-family: 'bootstrap-icons';
    color: #333;
    opacity: 1;
    font-size: 21px;
    position: absolute;
    left: 0;
    line-height: normal;
}

.paginationCustom li:nth-last-child(2) button::before {
    /* content: "\F133"; */
    font-family: 'bootstrap-icons';
    color: #333;
    opacity: 1;
    font-size: 20px;
    position: absolute;
    right: 5px;
    line-height: normal;
}

.paginationCustom li:last-child button::before {
    /* content: "\F231"; */
    font-family: 'bootstrap-icons';
    color: #333;
    opacity: 1;
    font-size: 17px;
    position: absolute;
    right: 0;
    line-height: normal;
}

.paginationCustom li .rpb-item--active {
    background-color: #1C1C1C;
    color: #fff !important;
}

.paginationCustom li button {
    width: 29px;
    height: 29px;
    min-width: 29px;
    font-size: 14px;
    /* border-radius: 100%; */
}

ul {
    padding: 1rem;
}

.table td {
    padding-left: 20px;
    white-space: nowrap;
}

.table th {
    padding-left: 20px;
}


.commonModel .modal-dialog {
    margin-top: 20%;
    width: 35%;
}

.commonNewModel {
    margin-top: 10%;
    padding-left: 30%;
    width: 100%;
}

.commonModel {
    padding-left: 30%;
}


.log-out-footer {
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #181818;
}

.commonModel .modal-content {
    border-radius: 17px;
    padding: 4%;
}

.commonNewModel .modal-content {
    border-radius: 17px;
    padding: 4%;
}

/* body{
    overflow-y: hidden;
} */

/* .carousel-caption{
    top: 90% !important;
} */

.carousel-item img {
    max-width: 100%;
    /* Decrease image width */
}

.carousel-item .text-center {
    text-align: center;
    /* Center text */
}

.text-decoration-none {
    text-decoration: none !important;
    /* Remove button underline */
    border: none !important;
    /* Remove button border */
    background-color: transparent !important;
    /* Remove button background color */
    cursor: pointer;
}

.carousel-indicators {
    margin-bottom: 7rem !important;
}

.carousel-indicators button[data-bs-target] {
    border-radius: 50%;
    background-color: #2CBC87 !important;
    width: 8px;
    height: 8px;
    margin: 0 5px;
}


.text-decoration-none:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    margin-top: 5px;
    /* Adjust dotted line position */
    background-color: currentColor;
    /* Match button text color */
    transition: width 0.3s;
}

.text-decoration-none:hover:after {
    width: 100%;
    /* Expand dotted line on hover */
}

.login-header-text {
    font-family: "Felipa";
    font-size: 34px;
    font-weight: 400;
    letter-spacing: -0.40799999237060547px;
    text-align: center;
    color: #FF0000
}

.login-header-text_sub {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #818181;
}

.form-control {
    border: 1px solid #DFDFDF;
}

.form-check-input:checked {
    background-color: #2CBC87;
    border-color: #2CBC87;
}

#sidebar:hover path {
    stroke: white;
}

#sidebarPage:hover svg path {
    fill: white;
}


#sidebarPage:hover a {
    color: white;
}
#sidePage{
    width: 96%;
    border-radius: 7px;
    padding-left: 7%;
    padding-top: 2%;
    padding-bottom: 2%;
    list-style: none;
    color: #313D15;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    /* line-height: 60px; */
    text-align: left;
    cursor: pointer;
}
#sidebar.active g path {
    stroke: white;
}

#sidebar:hover a {
    color: white;
}

#sidePage:hover g path {
    stroke: white;
    color: white;
}

#sidebar:hover a {
    color: white;
}


#sidebarPage {
    width: 96%;
    border-radius: 7px;
    padding-left: 7%;
    padding-top: 2%;
    padding-bottom: 2%;
    list-style: none;
    color: #313D15;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    /* line-height: 60px; */
    text-align: left;
    cursor: pointer;
}

.modal-footer {
    border: 0px !important;
}

.long-text-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.paginationCustom li:hover {
    background-color: white !important;
}

.paginationCustom li button:hover {
    background-color: rgb(12, 12, 12) !important;
    color: white !important;
}

#datepickField .react-datepicker__close-icon {
    right: 38px;
}

#datepickField .react-datepicker__close-icon::after {
    background-color: red;
}

#datepickField input {
    border-radius: 0px;
    height: 38px;
    width: 100%;
    font-size: 13px;
    box-shadow: none !important;
    border: 1px solid #dee2e6 !important;
    padding: .375rem .75rem;
    outline: none !important;
    background-image: url("../image/calendar.png");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 90% center;
}

#datepickField.min input {
    background-position: 96% center;
}

.react-datepicker .react-datepicker__month-select {
    height: auto !important;
    padding: 1px 5px;
    border-radius: 8px;
}

.react-datepicker .react-datepicker__year-select {
    height: auto !important;
    padding: 1px 7px;
    border-radius: 8px;
    width: 70px;
}

#datepickField {
    float: left;
    width: 22%;
    /* position: relative; */
}

#datepickFieldPayment {
    float: left;
    width: 27%;
    /* position: relative; */
}

#datepickFieldPayment .react-datepicker__close-icon {
    right: 38px;
}

#datepickFieldPayment .react-datepicker__close-icon::after {
    background-color: red;
}

#datepickFieldPayment input {
    border-radius: 0px;
    height: 38px;
    width: 100%;
    font-size: 13px;
    box-shadow: none !important;
    border: 1px solid #dee2e6 !important;
    padding: .375rem .75rem;
    outline: none !important;
    background-image: url("../image/calendar.png");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 90% center;
}

.react-datepicker-wrapper {
    width: 100%;
}

/* .file-upload {
    border: 1px dashed #D1D1D1;
    cursor: pointer;
    background-color: #FFFFFF;
    padding-top: 2%;
    width: 20%;
    padding-left: 1%;
} */

/* .file-upload p {
    font-size: 0.87rem;
    margin-top: 10px;
    color: #bbcada;
} */

.file-upload input {
    opacity: 0;
    cursor: pointer;
}

.image-color {
    background-color: #2CBC87;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 13px;
    border-radius: 7px;
}

.Tab a:hover,
.Tab .active {
    /* color: #34b739; */
    /* border-bottom: #34b739; */
    border-bottom: 2px solid #2CBC87;
}

.Tab {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #494949;
}


.remove-icon {
    position: absolute;
    top: -60px;
    right: -6px;
    z-index: 9999999;
    height: 25px;
    width: 25px;
    background-color: red;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    padding-left: 1%;
}


.file-upload {
    width: 20%;
    height: 20%;
    position: relative;
    border: 2px  dashed #D1D1D1;
    padding-top: 30px;
    margin-left: 2%;
    cursor: pointer;
}

.text-banner-upload {
    position: absolute;
     /* left: 38%; */
    top: 28%; 
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    background: #FFFFFF;

}

.subtitle-text-banner-upload {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #8B8E81;
    position: relative;
    margin-left: 4%;
}
.amount_subtitle{
    font-family: "Inter";
    font-size: 18px;
    font-style: italic;
    font-weight: 200;
    line-height: 23.4px;
    text-align: left;
    color: #000000;
}

#collapse-heading span{
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    text-align: center;
    color: #000000;
}

.zoom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
  }

  .zoom-overlay img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  .viewScroll{
    overflow-x: hidden;
  }
  /* .row{
    --bs-gutter-x: 0px !important;
  } */

  #contact-us-table tr td{
        border: 1px solid black;
  }